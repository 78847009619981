import { h } from 'preact';
import { useCallback, useEffect } from 'preact/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { CodeField, Spinner } from '@payhawk/hawky-react';
import { getSearch } from '../../utils/Browser';
import { useAuth } from '../../auth';
import CardDetails from './CardDetails';
import InitSecurityCode from './InitSecurityCode';
import styles from './VerifySecurityCode.module.scss';
import { useUpdateHeight } from '../../utils/useUpdateHeight';
import SecurityCodeDisabled from './SecurityCodeDisabled';
import SecurityCodeError from './SecurityCodeError';

const search = new URLSearchParams(getSearch());
const cdeToken = search.get('cdeToken') || '';

const VerifySecurityCode: preact.FunctionalComponent = () => {
    const [authState, authActions] = useAuth();
    const { t } = useTranslation(['common']);

    useEffect(() => {
        if (authState.hasSecurityCode !== undefined) {
            return;
        }
        void authActions.hasSecurityCode();
    }, []);

    useEffect(() => {
        const { securityCode } = authState;
        if (!securityCode) {
            return;
        }
        const autoGetCardDetails = async () => {
            await authActions.getCardDetails(cdeToken, securityCode);
        };
        void autoGetCardDetails();
    }, [authState.securityCode]);

    useUpdateHeight();

    const onForgotCodeClicked = useCallback(() => {
        authActions.resetSecurityCode();
    }, [authActions]);

    const onComplete = useCallback((code: string) => {
        void authActions.getCardDetails(cdeToken, code);
    }, [authActions]);

    const errorMessage = authState.resetSecurityCodeError || authState.cardDetailsError || '';

    const { hasSecurityCode, hasSecurityCodeError, isGettingSecurityCode, isSendingResetSecurityCode, isGettingCardDetails, hasExceededMaximumRetries, remainingRetryCount } = authState;

    if (isGettingSecurityCode || isSendingResetSecurityCode || isGettingCardDetails) {
        return <Loading />;
    }

    if (hasSecurityCodeError) {
        return <SecurityCodeError />;
    }

    if (!hasSecurityCode) {
        return <InitSecurityCode />;
    }

    if (hasExceededMaximumRetries) {
        return <SecurityCodeDisabled />;
    }

    const cardDetails = authState.cardDetails;

    if (cardDetails) {
        return <CardDetails />;
    }

    return (
        <div class={styles.container} key='verify-security-code'>
            <CodeField
                digitCount={4}
                onComplete={onComplete}
                className={styles.code}
                isMasked
                initialAutoFocus
            />
            <div className={styles.wrap}>
                <div className={styles.error}>
                    {remainingRetryCount ? (
                            <Trans
                                ns={'common'}
                                i18nKey={'incorrectCodeAttemptsRemaining'}
                                values={{ attempts: remainingRetryCount }} />
                        )
                        : (errorMessage || <p/>)}
                </div>
                <div className={styles.actions} onClick={onForgotCodeClicked}>
                    {t('common:forgotCode')}
                </div>
                <div className={styles.warning}>{t('common:securityCodeWarning')}</div>
            </div>
        </div>
    );
};

export default VerifySecurityCode;

export const Loading = () =>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
        <Spinner size='large' />
    </div>;
