import { IConfig } from '.';
import { DevelopmentConfig } from './DevelopmentConfig';
import { ProductionConfig } from './ProductionConfig';
import { getStagingConfig } from './StagingConfig';

const SIT_REGEX = /^https:\/\/portal\.sit-(\d+)\.payhawk\.com/;

function getConfig(): IConfig {
    switch (ENVIRONMENT) {
        case 'production':
            return ProductionConfig;
        case 'staging':
            const hostname = document.referrer.toLowerCase();
            const sitMatch = SIT_REGEX.exec(hostname);
            const config = getStagingConfig();

            if (sitMatch) {
                const sitNumber = sitMatch[1];
                config.API_URL = `https://api.sit-${sitNumber}.payhawk.com`;
                config.PORTAL_URL = `https://portal.sit-${sitNumber}.payhawk.com`;
            }

            return config;
        default:
            return getStagingConfig();
    }
}

export const CONFIG = getConfig();
