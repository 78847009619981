import { h } from 'preact';
import { useEffect, useMemo } from 'preact/hooks';
import {
    Trans,
    useTranslation
} from 'react-i18next';
import { Alert, classNames } from '@payhawk/hawky-react';
import { useAuth } from '../../auth';
import { getSearch } from '../../utils/Browser';
import { CopyableText } from './CopyableText';
import { useUpdateHeight } from '../../utils/useUpdateHeight';
import styles from './CardDetails.module.scss';

const AMEX_CARD_NUMBER_LENGTH = 15;

enum BillingAddressFieldEnum {
    CompanyName = 'companyName',
    Address = 'address',
    Vat = 'vat',
    Zip = 'zip',
    City = 'city',
    Country = 'country',
}

const formatCardNumber = (cardNumber: number) => {
    const cardNumberString = cardNumber.toString();

    // Format Amex cards
    if (cardNumberString.length === AMEX_CARD_NUMBER_LENGTH) {
        return cardNumberString.replace(/\b(\d{4})(\d{6})(\d{5})\b/, '$1 $2 $3');
    }

    return cardNumberString.replace(/(\d{4})+?/g, '$& ').trim();
};

const search = new URLSearchParams(getSearch());
const cardHolder = search.get('cardHolder') || '';

const CardDetails: preact.FunctionalComponent = () => {
    const [authState, authActions] = useAuth();
    const { t } = useTranslation(['common']);

    useEffect(() => {
        authActions.cardDetailsOpened();
    }, [authActions]);

    useUpdateHeight();

    const billingDetails = useMemo(() => (
        Object.values(BillingAddressFieldEnum)
            .reduce<{ key: string, value: string }[]>((acc, field) => {
                if (search.has(field)) {
                    acc.push({ key: t(`common:card.${field}`), value: search.get(field) || '' });
                }

                return acc;
            }, [])
    ), [search, t]);

    const {
        cvv,
        cardNumber,
        expirationDate,
    } = authState.cardDetails || {};
    const formattedCardNumber = cardNumber ? formatCardNumber(cardNumber) : '';

    return (
        <div class={styles.container} key='card-details'>
            <Alert
                type='info'
                startTitle={t('common:card.neverShareDetailsMessageTitle')}
                description={
                    <Trans
                        ns='common'
                        i18nKey='card.neverShareDetailsMessageBody'
                        components={{ br: <br /> }}
                    />
                }
            />
            <div class={styles.cardInfoContainer}>
                <CopyableText
                    value={cardNumber}
                    formattedValue={formattedCardNumber}
                    label={t('common:card.cardNumber')}
                    className={styles.card}
                    labelClassName={styles.cardLabel}
                    valueClassName={styles.cardValueWrapper}
                    showCopiedOverIcon />
                <CopyableText
                    value={cardHolder}
                    label={t('common:card.cardHolder')}
                    className={styles.card}
                    labelClassName={styles.cardLabel}
                    valueClassName={styles.cardValueWrapper}
                    showCopiedOverIcon />
                <div className={styles.groupedCards}>
                    <CopyableText
                        value={expirationDate}
                        label={t('common:card.expiryDate')}
                        className={classNames(styles.card, styles.cardSmall)}
                        labelClassName={styles.cardLabel}
                        valueClassName={styles.cardValueWrapper}
                        showCopiedOverIcon />
                    <CopyableText
                        value={cvv}
                        label={t('common:card.cvv')}
                        className={classNames(styles.card, styles.cardSmall)}
                        labelClassName={styles.cardLabel}
                        valueClassName={styles.cardValueWrapper}
                        showCopiedOverIcon />
                </div>
            </div>
            <div className={styles.billingAddressWrapper}>
                <div className={styles.billingTitle}>{t('common:card.billingAddress')}</div>
                {billingDetails.map(({ key, value }) => (
                    <CopyableText key={key} label={key} value={value} />
                ))}
            </div>
        </div>
    );
};

export default CardDetails;
