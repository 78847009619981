import { IconName, IInlineEditInputTooltips, IInputBaseProps, ITextInputLoadingProps, ITextInputOnChangeProps, ITextInputProps, TextInput } from '@payhawk/hawky-react';
import { h, JSX } from 'preact';
import { useAutoFocus } from './useAutoFocus';
// https://www.w3.org/WAI/WCAG21/Techniques/html/H98
// https://www.w3.org/WAI/standards-guidelines/act/rules/73f2c2/#attribute-value
// https://html.spec.whatwg.org/#autofill-field
// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
// https://caniuse.com/?search=autocomplete
export enum AutocompleteValues {
    On = 'on',
    Off = 'off',
    OneTimeCode = 'one-time-code',
    WorkEmail = 'work email',
    WorkPhone = 'work tel',
    CurrentPassword = 'current-password webauthn',
}
interface ITextInputPropsExtended extends IInputBaseProps, ITextInputLoadingProps, IInlineEditInputTooltips {
    textarea?: boolean;
    maxRows?: number;
    value?: string | number;
    validationText?: string;
    regex?: RegExp;
    min?: number;
    max?: number;
    step?: number;
    minLength?: number;
    maxLength?: number;
    type?: 'email' | 'text' | 'password' | 'number' | 'search';
    pattern?: string;
    debounceInterval?: number;
    valueIconName?: IconName;
    innerRef?: any;
    indicator?: JSX.Element;
    inlineEdit?: boolean;
    onInlineSave?: (value?: string, name?: string) => void;
    valueIconOnClick?(): void;
    onChange?(props: ITextInputOnChangeProps): void;
    onDebouncedChange?(value?: string): void;
    onStartedChanging?(value?: string): void;
    onKeyDown?(e: any): void;
    onKeyUp?(e: any): void;
    onHover?(isHovered: boolean): void;
    autocomplete?: AutocompleteValues | 'on' | 'one-time-code';
}

export const TextInputAutoFocus = (props: ITextInputPropsExtended): JSX.Element => {
    const inputRef = useAutoFocus();

    return (
        <TextInput
            innerRef={inputRef}
            {...props as ITextInputProps}
        />
    );
};
